import React, { useRef } from "react"

import "modern-normalize/modern-normalize.css"
import "../css/base.css"
import "../css/home.css"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Social from "../components/Social"
import Nav from "../components/Nav"
import Video from "../components/Video"
import videos from "../util/videos"

import logo from "../img/logo-black.png"

export default function Home() {
  const navScreen = useRef(null)

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "home.jpg" }) {
        ...jpgFragment
      }
    }
  `)

  return (
    <main className="Home">
      <div className="Home__nav">
        <div id="home">
          <figure
            onClick={() =>
              navScreen.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            <img src={logo} alt="Second&nbsp;Floor&nbsp;Events" />
          </figure>
        </div>
        <div id="nav" ref={navScreen}>
          <Nav />
          <Social />

          <hr />

          <p>
            461 King West,
            <br />
            Toronto
            <br />
            <br />
            <a href="tel:+1-416-263-0122">(416) 263-0122</a>
            <br />
            <br />
            <a href="mailto:info@2ndfloorevents.com">Email Us</a>
          </p>
        </div>
      </div>
      <figure className="Home__video">
        <Video playback_id={videos.homepage} />
      </figure>
    </main>
  )
}
